@import './src/scss/variables';
@import './src/scss/app';

.widget__cps {
	&-container {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  cursor: pointer;
	  user-select: none;
    flex-direction: column;
    gap: 1rem;
    height: $max-height;
	}

	&-start {
		display: flex;
	  align-items: center;
	  gap: .5rem;
	}

	&-progress {
	  &_list {
	    display: flex;
	    flex-wrap: wrap;
	    align-items: center;
	    justify-content: center;
	    gap: 1rem;

		  li {
		    display: flex;
		    align-items: center;
		    gap: .5rem;

		    a {
			    display: flex;
			    align-items: center;
			    gap: .5rem;
		    }
	    }
	  }
	}
}

#cps-test {
	&.result {
		cursor: default;

		.md-click-circle {
			display: none;
			visibility: hidden;
		}
	}
}

.material-click {
  overflow: hidden;
  position: relative;

  .md-click-circle {
    display: block;
    position: absolute;
    background: rgba(#000, .2);
    border-radius: 50%;
    opacity: 0;
    transform: scale(0);

    &.md-click-animate {
      animation: mdClickEffect .65s linear;
    }
  }
}

@keyframes mdClickEffect {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
  }

  100% {
    opacity: 0 !important;
    transform: scale(2.5);
  }
}